/* const heroImage = document.querySelector('.hero__image');
const heroText = document.querySelector('.hero__main');
const footer = document.querySelector('footer');

gsap.registerPlugin(ScrollTrigger);

if (window.innerWidth >= 991) {
  gsap.to(heroImage, {
    opacity: 1,
    x: 0,
    duration: 1.5,
    ease: "power2.out",
    scrollTrigger: {
      trigger: heroImage,
      start: 'top 70%',
      end: 'top 50%',
      scrub: true,
      markers: true
    }
  });

  gsap.to(heroText, {
    opacity: 1,
    x: 0,
    duration: 1.5,
    ease: "power2.out",
    scrollTrigger: {
      trigger: heroText,
      start: 'top 80%',
      end: 'top 50%',
      scrub: true,
      markers: true
    }
  });

  gsap.to(footer, {
    opacity: 1,
    x: 0,
    duration: 1,
    ease: "power2.out",
    scrollTrigger: {
      trigger: heroText,
      start: 'top 80%',
      end: 'top 50%',
      scrub: true,
      markers: true
    }
  });
} else {
  gsap.to(heroImage, {
    opacity: 1,
    y: 0,
    duration: 1.5,
    ease: "power2.out",
    scrollTrigger: {
      trigger: heroImage,
      start: 'top 70%',
      end: 'top 50%',
      scrub: true,
      markers: true
    }
  });

  gsap.to(heroText, {
    opacity: 1,
    y: 0,
    duration: 1.5,
    ease: "power2.out",
    scrollTrigger: {
      trigger: heroText,
      start: 'top 80%',
      end: 'top 50%',
      scrub: true,
      markers: true
    }
  });

  gsap.to(footer, {
    opacity: 1,
    y: 0,
    duration: 1,
    ease: "power2.out",
    scrollTrigger: {
      trigger: heroText,
      start: 'top 80%',
      end: 'top 50%',
      scrub: true,
      markers: true
    }
  });
}
 */